<template>
  <div class="member-shop-order-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="訂單編號" prop="code" align="center" />
      <el-table-column label="訂單日期" prop="createdAt" align="center" />
      <el-table-column label="姓名" prop="name" align="center" />
      <el-table-column label="訂單狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">{{ scope.row.status.label }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="支付資訊" prop="displayPrice" align="center" width="140" />
      <el-table-column label="付款方式" prop="paymentType" align="center" />
      <el-table-column label="付款狀態" prop="paymentStatus" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">{{ scope.row.status.label }}</Tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <el-button class="text-primary-100 underline" type="text" @click="onRowView(scope.row)">檢視</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Tag from '@/components/Tag/Tag.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '@/utils/date'
import { get } from 'lodash'
import { orderStatusConfig, orderPaymentStatusConfig, orderPaymentTypeConfig } from '@/config/memberShop'

export default defineComponent({
  name: 'MemberShopOrderTable',
  components: { Tag, EmptyBlock },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  setup (props) {
    const router = useRouter()
    const displayData = computed(() => {
      return props.tableData.map(i => {
        const orderStatus = get(orderStatusConfig, i.status)
        const paymentStatus = get(orderPaymentStatusConfig, get(i, 'MemberStoreOrderPayment.status'))
        const paymentType = get(orderPaymentTypeConfig, get(i, 'MemberStoreOrderPayment.paymentType'))

        const point = i.pointExchangeAmount
        const price = i.paidAmount
        let displayPrice = '-'
        if (!price) displayPrice = `${point} 點`
        if (price && point) displayPrice = `${point} 點 + $ ${price}`

        return {
          code: i.code,
          id: i.id,
          createdAt: formatDate(i.createdAt),
          name: get(i, 'Member.UserInfo.name'),
          status: orderStatus,
          displayPrice,
          paymentType: paymentType.label,
          paymentStatus,
        }
      })
    })

    const onRowView = (row) => {
      router.push({ name: 'MemberShopPointExchangeRecordDetail', params: { id: row.id } })
    }

    return {
      displayData,
      onRowView,
    }
  },
})
</script>

<style scoped lang="postcss">

</style>
